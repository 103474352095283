<template>
    <div class="container" id="scrollArea">
        <!-- 轮播图 -->
        <div class="carousel">
            <div class="swiper-container swiper-no-swiping" ref="mySwiper">
                <div class="swiper-wrapper" :interval="5000"
                     :height="980 + 'px'">
                    <div class="swiper-slide" v-for="item in slidShowImgArr"
                         :key="item.id">
                        <img :src="item.swiper_imgPath"/>
                        <div class="swiper_slide_info">
                            <h3 class="swiper_title">{{
                                    item.swiper_title
                                }}</h3>
                            <span class="swiper_sp1">{{
                                    item.swiper_sp1
                                }}</span>
                            <span class="swiper_sp2">{{
                                    item.swiper_sp2
                                }}</span>
                            <span class="swiper_sp3">{{
                                    item.swiper_sp3
                                }}</span>
                             <div class="title_line animated bounceInLeft"></div>
                             <small class="animated bounceInRight" @click="immediately">立即咨询</small>
                        </div>
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>
        </div>

        <div class="container_architecture" v-for="item in nainList"
             :key="item.id">
            <div class="cooperativeUser_title">
                <h2 class="main_catchword_h2">
                    {{ item.title }}</h2>
            </div>
            <div style=" display: flex; justify-content: center;">
                <img :src="item.superiorityIcon">
            </div>
        </div>

        <!-- 内容 技术支持-->
        <div class="container_technicalSupport">
            <div class="cooperativeUser_title">

                <h2 class="main_catchword_h2">小投入，大回报</h2>
            </div>
            <div class="technicalSupport">
                <div class="technicalSupport_shade">
                    <ul class="technicalSupport_ul">
                        <li v-for="item in technicalSupportList.data"
                            :key="item.id + ''">
                            <!-- <div> -->
                                <i :class="item.icon"></i>
                                <em>{{ item.title }}</em>
                                <p>
                                    {{ item.content }}
                                </p>
                            <!-- </div> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- 内容 第六部分  底部-->
        <div class="homeBottomPart">
            <common-BottomPart :emailPhoneInfo="emailPhone"
                               :codeInfo="codeQR"></common-BottomPart>
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import commonProduct from '../productRecommendation/recommendation.vue'
import commonBottomPart from '../bottomPart/bottomPart.vue'
import commonFloatWin from '../floatingWindow/floatingWindow.vue'
import demand from '../../api/data.js'
import $ from 'jquery'
import Swiper from 'swiper'
// //引入swiper样式
import 'swiper/css/swiper.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
export default {
    inject: ['reload'],
    /**
     * param slidShowImgArr 顶部轮播图
     * param catchword 内容阐述
     * param technicalSupportList 技术支持文本列表
     * param equipmentOnCloudList 设备上云列表
     * param slidShowMaxHgh: 顶部轮播图高度,canvasHeight: 顶部轮播图颗粒动态特效,
     * param swiperOption 公司logo轮播配置
     * param platformFunction 平台功能
     * param productList  产品推荐列表
     * param emailPhone 咨询热线
     * param codeQR 二维码
     */
    components: {
        // commonProduct,
        commonBottomPart,
        commonFloatWin
    },
    mounted() {
        ;(window.onresize = () => {
            this.changeShowMaxHgh()
        }),
            this.changeShowMaxHgh()
        // 初始化数据
        // this.getHomeResource()
        ;(this.mySwiper = new Swiper('.swiper-container', {
            // direction: 'vertical', // 垂直切换选项
            loop: true, // 循环模式选项
            autoplay: {
                // speed:5000,
                delay: 5000,
                stopOnLastSlide: false,
                disableOnInteraction: false
                // pauseOnMouseEnter:true,
            }, //自动切换
            followFinger: false,
            simulateTouch: false,
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },

            // 如果需要滚动条
            scrollbar: {
                el: '.swiper-scrollbar'
            }
        })),
            //初始化滑块宽度
            this.initWidth()

        this.$refs.mySwiper.swiper.autoplay.stop()
        this.$refs.mySwiper.swiper.autoplay.start()
        //鼠标移入停止播放，鼠标离开  继续轮播
        // console.log(this.mySwiper);
        //     // this.mySwiper.el.onmouseenter=function(){
        //     //    this.mySwiper.autoplay.stop();
        //     // }
        //     this.mySwiper.el.onmouseleave=function(){
        //       this.mySwiper.autoplay.start();
        //     }
        // this.swiperMouseenter()
        // this.swiperMouseleave()

        // 动画
        // window.addEventListener('scroll', this.handleScroll, true)
    },
    destroyed() {
        window.onresize = null
        // window.removeEventListener('scroll', this.handleScroll, false)
    },
    data() {
        return {
            fit: 'cover',
            nainList: [
                {
                    id: '1',
                    title: '制造业数字化发展路径',
                    superiorityIcon: require('../../assets/img/home/main1.png')

                },
                {
                    id: '2',
                    title: '架构图',
                    superiorityIcon: require('../../assets/img/home/architecture.png')
                },
            ],
            slidShowImgArr: [
                {
                    id: 1,
                    swiper_imgPath: require('../../assets/img/titlebackground/titleB24.jpg'),
                    swiper_title: '制造业一站式数字化转型服务',
                    swiper_sp1: '分工合作',
                    swiper_sp2: '生产效率',
                    swiper_sp3: '良品率'
                },
                {
                    id: 2,
                    swiper_imgPath: require('../../assets/img/7.jpg'),
                    swiper_title: '制造业一站式数字化转型服务',
                    swiper_sp1: '分工合作',
                    swiper_sp2: '生产效率',
                    swiper_sp3: '良品率'
                },
                {
                    id: 3,
                    swiper_imgPath: require('../../assets/img/home/homeBackground.jpg'),
                    swiper_title: '制造业一站式数字化转型服务',
                    swiper_sp1: '分工合作',
                    swiper_sp2: '生产效率',
                    swiper_sp3: '良品率'
                },
                {
                    id: 5,
                    swiper_imgPath: require('../../assets/img/home/titleB52.jpg'),
                    swiper_title: '制造业一站式数字化转型服务',
                    swiper_sp1: '分工合作',
                    swiper_sp2: '生产效率',
                    swiper_sp3: '良品率'
                },
                {
                    id: 7,
                    swiper_imgPath: require('../../assets/img/home/homeBackground2.jpg'),
                    swiper_title: '制造业一站式数字化转型服务',
                    swiper_sp1: '分工合作',
                    swiper_sp2: '生产效率',
                    swiper_sp3: '良品率'
                },
            ],

            catchword: {
                title: '小标题区域1',
                description: '小标题区域2',
                remark: '文字区域'
            },

            technicalSupportList: {
                data: [
                    {
                        id: 14,
                        icon: 'fa fa-code-fork',
                        title: '60%',
                        content: '降低纸张使用和沟通'
                    },
                    {
                        id: 15,
                        icon: 'fa fa-bar-chart  ',
                        title: '70%',
                        content: '减少数据汇总和分析工作量'
                    },
                    {
                        id: 16,
                        icon: 'fa fa-thumbs-up ',
                        title: '20%',
                        content: '提高工作效率'
                    },
                    {
                        id: 17,
                        icon: 'fa fa-database',
                        title: '30%',
                        content: '降低库存堆积'
                    },
                    {
                        id: 18,
                        icon: 'fa fa-tablet',
                        title: '30%',
                        content: '降低次品率和次品成本'
                    },
                    {
                        id: 19,
                        icon: 'fa fa-clock-o',
                        title: '20%',
                        content: '缩短制造周期'
                    }
                ],
                title: '小标题区域'
            },

            slidShowMaxHgh: 200,
            canvasHeight: 500,
            swiperOption: {
                loop: true,
                slidesPerView: 9,
                speed: 5000,
                autoplay: {
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
            },

            productList: [],
            emailPhone: {},
            codeQR: [],
            activeName: 'first'
        }
    },

    computed: {
        swiper() {
            return this.$refs.mySwiper1.swiper
        }
    },

    methods: {
        // 立即咨询
        immediately() {
            this.reload()
            this.$router.push({name: 'Immediately'})
        },
        // 滚动监听 动画监听
        handleScroll(e) {
            let scrollHeight = e.target.scrollTop // 滚动条的滚动行程
            // console.log(scrollHeight)
            if (scrollHeight > 300) {
                //第一部分
                $('.main_catchword').removeClass('displayNone')
                $('.main_catchword > .line_top').addClass('animated bounceInLeft')
                $('.main_catchword > .line_bottom').addClass('animated bounceInRight')
                $('.main_catchword > .main_catchword_h2').addClass(
                    'animated bounceInDown'
                )
                $('.main_catchword > .main_catchword_em').addClass('animated bounceIn')
                $('.main_catchword > .main_catchword_p').addClass('animated bounceInUp')
            }
            if (scrollHeight > 700) {
                //技术支持区域
                $('.technicalSupport').removeClass('displayNone')
                $('.technicalSupport_h2').addClass('animated rollIn')
                $('.technicalSupport_line').addClass('animated rotateIn')
                $('.technicalSupport_ul > li').addClass('animated bounceInUp')
            }
            if (scrollHeight > 1300) {
                //设备上云
                $('.equipmentOnCloud').removeClass('displayNone')
                $('.equipmentOnCloud_title > .line_top').addClass(
                    'animated bounceInLeft'
                )
                $('.equipmentOnCloud_title > .line_bottom').addClass(
                    'animated bounceInRight'
                )
                $('.equipmentOnCloud_title > .main_catchword_h2').addClass(
                    'animated bounceInDown'
                )
                $('.equipmentOnCloud_title > .main_catchword_em').addClass(
                    'animated bounceIn'
                )
                $('.equipmentOnCloud_title > .main_catchword_p').addClass(
                    'animated bounceInUp'
                )
                $('.equipmentOnCloud_ul > li').addClass('animated bounceInUp')
            }
            if (scrollHeight > 1900) {
                //合作伙伴
                $('.cooperativePartner').removeClass('displayNone')
                $('.cooperativePartner_title > .line_top').addClass(
                    'animated bounceInLeft'
                )
                $('.cooperativePartner_title > .line_bottom').addClass(
                    'animated bounceInRight'
                )
                $('.cooperativePartner_title > .main_catchword_h2').addClass(
                    'animated bounceInDown'
                )
                $('.cooperativePartner_title > .main_catchword_em').addClass(
                    'animated bounceIn'
                )
                $('.cooperativePartner_title > .main_catchword_p').addClass(
                    'animated bounceInUp'
                )
            }
        },

        // 监听页面缩放
        changeShowMaxHgh() {
            let height = document.body.offsetHeight // 网页可视区域高度
            this.slidShowMaxHgh = height
            // console.log('height:' + this.slidShowMaxHgh)
        },

        //  初始化方法
        initWidth() {
            // 三大产业——pc
            if ($(window).width() > 950) {
                console.log($(window).width())
                $('.g_pt1qienr').hover(function () {
                    var pag = $(this).attr('data-num')
                    $('.g_pt1beinr').siblings('.g_pt1beinr').stop().removeClass('act')
                    $('.g_pt1beinr[data-num="' + pag + '"]')
                        .stop()
                        .addClass('act')
                    $(this).stop().addClass('on')
                    $(this).siblings().stop().removeClass('on')
                })
            }
            if ($(window).width() < 951) {
                console.log($(window).width())
                $('.g_pt1qienr').hover(function () {
                    var pag = $(this).attr('data-num')
                    $('.g_pt1beinr').siblings('.g_pt1beinr').stop().removeClass('act')
                    $('.g_pt1beinr[data-num="' + pag + '"]')
                        .stop()
                        .addClass('act')
                    $(this).stop().addClass('on')
                    $(this).siblings().stop().removeClass('on')
                })
            }

            // 第二屏滑块效果
            if ($(window).width() > 950) {
                $('.g_pt1qienr').mouseenter(function (e) {
                    var index = $(this).index()
                    $('.nav-cont-item-bg', $(this).parent()).css({
                        top: index * 11.25 + 'rem'
                    })
                    $('.nav-cont-item-bg').stop().addClass('act')
                })
            }
            if ($(window).width() < 951) {
                $('.g_pt1qienr').mouseenter(function (e) {
                    var index = $(this).index()
                    $('.nav-cont-item-bg', $(this).parent()).css({
                        top: index * 23 + 'rem'
                    })
                    $('.nav-cont-item-bg').stop().addClass('act')
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    background: #ffffff;
    margin: 0;
    padding: 0;
    //   display: flex;
    //    justify-content: center;
}

.swiper-container {
    height: 650px;
    width: 1920px;
    position: relative;
}

// 轮播左右翻页
.swiper-button-prev {
    left: 3%;
}

.swiper-button-next {
    right: 5%;
}

.swiper-pagination {
    top: 96% !important;

    /deep/ .swiper-pagination-bullet {
        background: #2db5a3;
        width: 0.8rem;
        height: 0.8rem;
    }
}


small {
    font-size: 18px;
    position: absolute;
    top: 60%;
    left: 80%;
    cursor: pointer;
    padding: 10px 20px;
    background: #2db5a3;
    border-radius: 4px;
    color: white
}

small:hover {
    font-weight: bold;
}

.title_line {
    width: 8%;
    height: 1px;
    background: #2db5a3;
    margin: 4.2% 0 0 68%;
}

.swiper_slide_info {
    width: 60%;
    height: 560px;
    position: relative;
    // position: absolute;
    text-align: center;
    margin: 18% 18%;
    // text-indent: 6rem;
    // line-height: 200%;
    font-size: 30px;
    z-index: 5;
    color: white;
    margin-top: 180px;

    .swiper_title {
        text-align: center;
        font-size: 42px;
        font-weight: lighter;
        line-height: 72px;
        color: #fff;
        //letter-spacing: 2px;
        margin-bottom: 20px;
        font-family: 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial,
        sans-serif;
        color: #f5f7fa;
        text-shadow: 0 8px 9px #0e0800, 0px -2px 1px #fff;
        font-weight: bold;
        //letter-spacing: 2px;
        // background: linear-gradient(to bottom, #ece4d9 0%, #e9dfd1 100%);
    }

    .swiper_sp1 {
        color: #fff;
        text-align: center;
        font-size: 18px;
        height: 72px;
        line-height: 72px;
        //letter-spacing: 2px;
        display: block;
    }

    .swiper_sp2 {
        color: #fff;
        text-align: center;
        font-size: 24px;
        height: 72px;
        line-height: 72px;
        //letter-spacing: 2px;
        display: block;
    }

    .swiper_sp3 {
        color: #fff;
        text-align: center;
        font-size: 20px;
        height: 72px;
        line-height: 72px;
        //letter-spacing: 2px;
        display: block;
    }
}

img {
    margin: 40px 0;
    height: 900px;
    width: 1920px;
    position: absolute;
}

// 粒子效果
.vueParticles {
    z-index: 1;
    position: absolute;
    top: 0%;
    width: 100%;
}

.item {
    // display: inline;
    position: relative;
    height: 1180px;
    width: 1915PX;
    margin-bottom: 126PX;
    margin: 0 auto;
    // margin-top: -20px;
}

//轮播图一（滑块）
.swiper_slide_info2 {
    width: calc(100% - 480px);
    height: 960px;
    position: relative;
    // position: absolute;
    text-align: center;
    margin: 1% 12%;
    // text-indent: 6rem;
    // line-height: 200%;
    font-size: 30px;
    z-index: 5;
    color: white;

    // border: 1px solid red;
    /*导航效果-end*/

    .g_sycen {
        height: 910px;
        position: relative;
        z-index: 20;
        overflow-x: hidden;
        overflow-y: auto;
        // line-height: 1;
        // box-sizing: border-box;
        margin: 0;
        padding: 0;
        margin-top: 100px;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    /*公共*/

    .g_content {
        width: 1200px;
        height: 686px;
        margin: auto;
    }

    /*规范*/

    .g_word1 {
        font-size: 30px;
        // line-height: 1.75;
        //letter-spacing: 1px;
    }

    .g_word2 {
        font-size: 24px;
        line-height: 1.75;
        //letter-spacing: 1px;
    }

    .g_word3 {
        font-size: 16px;
        // line-height: 1.75;
        //letter-spacing: 1px;
    }

    .g_word4 {
        font-size: 14px;
        // line-height: 1.75;
        //letter-spacing: 1px;
    }

    /*载入效果*/

    .g_fadein1 {
        -webkit-animation: fadeIn 1s ease both;
        animation: fadeIn 1s ease both;
        animation-delay: 0.3s;
    }

    .g_pt1qieph {
        display: none;
    }

    .g_section1 {
        padding-top: 115px;
        position: relative;
    }

    /* 整体内部框 */

    .g_pt1beinr {
        display: none;
    }

    /* 内部框 */

    .g_pt1beinr.act {
        display: block;
    }

    .g_pt1bei {
        position: relative;
        z-index: 20;
        width: 893px;
        height: 415px;
    }

    .g_part1tle {
        width: 1050px;
        height: 90px;
        // position: relative;
        // border: 1px solid rgb(255, 251, 0);
        text-align: left;
        // left: -80px;
    }

    .g_part1tle h2 {
        color: #0c0c0c;
        // color: #111111;
        font-weight: lighter;
        line-height: 30px;
        font-size: 30px;
        //letter-spacing: 1px;
    }

    .g_part1tle h3 {
        color: #050505;
        // color: #111111;
        font-weight: bold;
        line-height: 30px;
        font-size: 30;
        //letter-spacing: 1px;
    }

    .g_part1qie {
        width: 1050px;
        // height: 455px;
        margin-top: 40px;
        position: relative;
        z-index: 2;
    }

    .g_pt1qie {
        width: 193.5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 725px;
        background: #3ad5d4;
        border-radius: 10px;
        /* background: #0044d2;  */
        /* background: #0a0c24; */
        z-index: 10;
    }

    .g_pt1beixx {
        width: calc(100% - 193.5px);
        /* background: #0044d2; */
        background: url('../../../src/assets/img/home/lunboHuakuai1.png') no-repeat;
        background-size: cover;
        padding: 10PX 0 0 92PX;
        height: 480PX;
        position: relative;
        border-top-left-radius: 15PX;
        border-bottom-left-radius: 15PX;
    }

    // .g_pt1beifr {
    //     width: 540px;
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    // }

    .g_pt1beifl {
        // width: calc(100% - 540px);
        width: 660px;
        height: 400px;
        // border: 1px solid brown;
        text-align: left;
    }

    .g_pt1beiflp {
        width: 393px;
        height: 20px;
        color: rgb(13, 14, 14);
        font-size: 16px;
        // line-height: 16px;
        margin-bottom: 5px;
        //letter-spacing: 1px;
    }

    .g_pt1beifl .g_word1 {
        color: rgb(0, 0, 0);
        //letter-spacing: 1px;
    }

    .g_pt1beiwz {
        margin-top: 24px;
    }

    .g_pt1beiwz .g_word3 {
        color: rgb(10, 10, 10);
        padding-left: 20px;
        position: relative;
        transition: all 0.36s;
    }

    .g_pt1beiwz .g_word3::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fff;
        transform: translateY(-50%);
    }

    .g_pt1beiwz .g_word4 {
        color: #5a5b5e;
        padding-left: 20px;
    }

    .g_pt1qietu {
        width: 100px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
    }

    .g_pt1qietu img {
        width: 100%;
        height: 100%;
        margin: 10px auto;
    }

    .g_pt1qietu1 {
        position: absolute;
        top: 0;
        left: 0;
        // border: 1px solid burlywood;
    }

    .g_pt1qietu2 {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        //  border: 1px solid burlywood;
    }

    //滑块
    .g_pt1qie .g_pt1qienr {
        // height: 25%;
        width: 220px;
        height: 180px;
        margin: 0;
        padding: 0;
        // width: 100%;
        position: relative;
        cursor: pointer;
        // border: 1px solid yellow;
    }

    .g_pt1qieb {
        position: absolute;
        z-index: 20;
        width: 193.5px;
        height: 113px;
    }

    .g_pt1qieb p {
        margin-top: 30px;
        font-size: 17px;
        line-height: 30px;
        color: #fff;
        height: 30px;
        width: 193.25px;
        // text-align: center;
        // border: 1px solid red;
    }

    // .nav-cont-item-bg {
    //   position: absolute;
    //   top: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   margin-top: -12px;
    //   width: 220px;
    //   height: 195px;
    //   border-radius: 20px;
    //   cursor: pointer;
    //   transition: all 0.36s;
    //   background-image: linear-gradient(to bottom right, #148ffa, #0044d2);
    // }
    .nav-cont-item-bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        // margin-top: -12px;
        width: 220px;
        height: 180px;
        // height: 195px;
        border-radius: 20px;
        cursor: pointer;
        transition: all 0.36s;
        background-image: linear-gradient(to bottom right, #ffffff, #27dcc7);
    }

    .nav-cont-item-bg.act {
        display: block;
    }

    /* 滑块透明度 */

    .g_pt1qienr.on .g_pt1qietu2 {
        opacity: 1;
    }

    .g_pt1qienr.on .g_pt1qietu1 {
        opacity: 0;
    }
}

.swiper_slide_info4 {
    width: 60%;
    height: 560px;
    position: relative;
    // position: absolute;
    text-align: center;
    // left: 30%;
    margin: 18% 30%;
    // text-indent: 6rem;
    // line-height: 200%;
    font-size: 30px;
    z-index: 5;
    color: white;

    .swiper_title {
        text-align: center;
        font-size: 90px;
        font-weight: lighter;
        line-height: 72px;
        color: #fff;
        //letter-spacing: 2px;
        margin-bottom: 20px;
    }

    .swiper_sp1 {
        color: #fff;
        text-align: center;
        font-size: 40px;
        height: 72px;
        line-height: 72px;
        //letter-spacing: 2px;
        display: block;
    }

    .swiper_sp2 {
        color: #fff;
        text-align: center;
        font-size: 60px;
        height: 72px;
        line-height: 72px;
        //letter-spacing: 2px;
        display: block;
    }

    .swiper_sp3 {
        color: #fff;
        text-align: center;
        font-size: 40px;
        height: 72px;
        line-height: 72px;
        //letter-spacing: 2px;
        display: block;
    }
}


.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.setCanvas {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.line_top {
    width: 80PX;
    height: 1PX;
    background: #666;
    margin: 80px 0 0 34%;
}

.line_bottom {
    width: 80PX;
    height: 1PX;
    background: #666;
    position: relative;
    top: 102PX;
    left: 61%;
}

.cooperative_line_bottom {
    width: 80PX;
    height: 1PX;
    background: #666;
    position: relative;
    top: 82PX;
    left: 61%;
}

.main_catchword_h2 {
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    margin-top: 100PX;
}

.main_catchword_em {
    font-style: inherit;
    font-size: 18px;
    font-weight: bold;
    //letter-spacing: 2px;
}

.main_catchword_p {
    padding: 40px 0;
    font-size: 14px;
    color: #333;
}

// 内容 第一部分
.main_catchword {
    z-index: 5;
    text-align: center;
    height: 220px;
}

//架构图
.container_architecture {
    height: 710PX;
    width: 1915PX;
    margin: 40px auto;
    .main_catchword_h2{
        margin-top: 85px;
    }
    img {
        height: 650PX;
        width: 1282PX;
        border-radius: 10px;
        object-fit: contain;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        margin-top: 20px;
        margin-bottom: 40px;
    }
}

// 内容 技术支持
.container_technicalSupport {
    height: 700px;
    // width: 1915PX;
    margin: 0 auto;


    .technicalSupport {
        // background: url('../../assets/img/6.jpg');
        background-size: cover;
        background-position: 100%;
        height: 480px;


        .technicalSupport_shade {
            color: rgb(22, 22, 22);
            width: calc(100% - 480px);
            height: calc(100% - 20px);
            // margin: 50px 0;
            padding: 10px 240px;
            text-align: center;
            background: #ffffff52;


            .technicalSupport_ul {
                // width: 100%;
                // margin: 10px 0 0 0;
                width: 1372PX;
                margin: auto;
            }

            .technicalSupport_ul > li {
                list-style: none;
                width: 26%;
                margin-left: 3%;
                height: 200px;
                float: left;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                margin-top: 15px;
            }

            .technicalSupport_ul > li:hover {
                box-shadow: 0 2px 8px rgba(17, 248, 237, 0.15);
            }

            .technicalSupport_ul > li:hover,
            .technicalSupport_ul > li > i {
                color: #2db5a3;
            }

            .technicalSupport_ul > li > i {
                display: block;
                font-size: 38px;
                margin: 3rem 0 20px 0;
            }

            .technicalSupport_ul > li > em {
                //letter-spacing: 2px;
                font-style: normal;
                font-size: 14px;
            }

            .technicalSupport_ul > li > p {
                //letter-spacing: 2px;
                line-height: 30px;
            }
        }
    }
}

// 内容 第三部分
.equipmentOnCloud {
    height: 820px;
    text-align: center;
    width: 1915PX;
    margin: 0 auto;

    .equipmentOnCloud_content {
        height: calc(100% - 280px);
        width: calc(100% - 410px);
        padding: 0 270px;
    }

    .equipmentOnCloud_ul > li {
        float: left;
        width: calc(23% - 80px);
        height: 540px;
        padding: 0 40px;
        margin: 0 20px 0 0;
        background: #fff;
        position: relative;
    }

    .equipmentOnCloud_circle {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 1px solid #2db5a3;
        margin: 40px auto;
    }

    .equipmentOnCloud_circle > i {
        font-size: 88px;
        color: #2db5a3;
    }

    .equipmentOnCloud_circle:hover {
        background: #2db5a3;
    }

    .equipmentOnCloud_circle:hover > i {
        color: #fff;
    }

    .equipmentOnCloud_ul > li > h3 {
        margin: 50px 0;
    }

    .equipmentOnCloud_ul > li > p {
        line-height: 30px;
    }

    .equipmentOnCloud_getMore {
        background: #2db5a3;
        position: absolute;
        right: 20%;
        bottom: 40px;
    }
}

// 内容 第四部分
.cooperativePartner {
    height: 430px;
    color: #fff;
    padding: 10px 240px;
    text-align: center;
    width: calc(100% - 480px);
    //   background: url('../../assets/img/titlebackground/homet1.jpg');
    background-size: cover;
    background-position: 100%;

    .line_top {
        background: #fff;
        margin: 30px 0 0 36%;
    }

    .line_bottom {
        background: #fff;
        left: 71%;
    }

    .main_catchword_p {
        color: #fff;
    }

    .swiper {
        height: 100%;
        margin-top: 30px;
    }

    .swiper-slide {
        height: 160px;
        //  background: #fff;
    }

    .cooperativePartner_img {
        width: 80px;
        height: 80px;
    }

    .swiper-container {
        margin-top: 20px;
    }
}

// 内容第五部分
.productRecommendation {
    height: 380px;
    margin-top: 20px;
}

// 内容 第六部分
.homeBottomPart {
    height: 420PX;
}

// 内容 平台功能 20220922
.platformFunction {
    height: 770px;
    width: 1915PX;
    margin: 0 auto;
    //   background: url('../../assets/img/home/technicalSupport.jpg');
    background-size: cover;
    background-position: 100%;

    // background: #00000052;
    .technicalSupport_shade {
        color: black;
        width: 1282PX;
        height: calc(100% - 40px);
        margin: 0 auto;
        padding: 20px 240px;
        text-align: center;
        background: #ffffff52;


        .technicalSupport_h2 {
            //letter-spacing: 2px;
            margin: 0;
            text-align: center;
        }

        .technicalSupport_line {
            height: 1px;
            background: chartreuse;
            width: 60px;
            position: relative;
            top: 20px;
            left: 49%;
        }
    }

    .el-tabs {
        margin-top: 40px !important;
        color: #000612;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

        .tabBody {
            color: #000000;
            height: 400px;
            margin-top: 20px;
            display: flex;

            .tabBody-img {
                width: 55%;

                img {
                    margin: 0;
                    position: relative;
                    border-radius: 10px 0 0 10px;
                    width: 100%;
                    height: 100%;
                }
            }

            .tabBody-content {
                width: 60%;
                height: 100%;
                background: rgb(255, 255, 255);
                border-radius: 0 10px 10px 0;
                border: 0.5px solid #dfe0e4;
                box-shadow: inset 0.5px 0 0.5px #bebfc2;

                h3 {
                    width: 60%;
                    text-align: center;
                    margin: 30px 20% 20px 20%;
                }

                p {
                    line-height: 30px;
                    text-indent: 2rem;
                    text-align: left;
                    width: 60%;
                    margin-left: 20%;
                }

                .describeArr {
                    line-height: 20px;
                }
            }
        }

        // 轮播图
        // .carousel2{
        //   height:500px;
        //   width: 1435px;
        //   .swiper-container{
        //     height: 450px;
        //     width: 1435px;
        //     position: relative;
        //     .swiper-slide{
        //       height: 450px;
        //       width: 100%;
        //       img{
        //         margin: 0;
        //         height:400px;
        //         width:800px;
        //         position: relative;
        //      }
        //     }
        //   /deep/.swiper-pagination-bullet {
        //     background: #5443ee;
        //     width: 10px;
        //     height: 10px;
        //         }
        //   }
        // }
        .el-carousel {
            height: 450px;

            .el-carousel__item {
                height: 425px;
                background: #000612;

                // background-color:rgba(255,255,255,0.6);
                // opacity: 0;
                img {
                    margin: 0;
                    height: 400px;
                    width: 800px;
                    position: relative;
                    opacity: 1;
                }
            }
        }
    }

    /deep/ .el-tabs__item {
        color: rgb(15, 15, 15);
    }

    /deep/ .el-tabs__item.is-active {
        color: #409eff;
    }
}

@media (min-width: 1080px) and (max-width: 1366px) {
}

/* 小屏幕（平板，大于等于 768px） */
// @media only screen and (max-width:767px) and (min-width: 375px) {
@media only screen and (max-width: 1068px) {
    .container {
        margin: 100px auto;
        font-size: 0.6375rem;
    }
    .homeBottomPart {
    height: 420px;
}

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 0 auto;
        .main_catchword_h2{
            margin-top: 18rem;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }
    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .cooperative_line_bottom {
        width: 7rem;
        top: 13.125rem;
    }

    .main_catchword_h2 {
        font-size: 4.875rem;
        font-weight: bold;
        margin-top: 18rem;
    }

    // 轮播图
    .carousel {
        height: 75.25rem;

        .swiper-container {
            height: 86rem;

            // text-indent: 6rem;
            // line-height: 200%;
            img {
                height: 77rem;
            }

            .swiper_slide_info {
                width: 86%;
                margin: 18% 7%;
            }

            .swiper_title {
                font-size: 5.625rem;
                height: 10.5rem;
                line-height: 7.5rem;
            }

            .swiper_sp1 {
                font-size: 3.625rem;
                height: 9.5rem;
                line-height: 5.5rem;
            }

            .swiper_sp2 {
                font-size: 3rem;
                height: 9.5rem;
                line-height: 5.5rem;
            }

            .swiper_sp3 {
                font-size: 4rem;
                height: 9.5rem;
                line-height: 8.5rem;
            }

            /deep/ .swiper-pagination-bullet {
                background: #ff5722;
                width: 1.6rem;
                height: 1.6rem;
            }

            .swiper-pagination[data-v-e9765834] {
                top: 94% !important;
            }
        }
    }

    .particles {
        // 溢出隐藏
        // overflow: hidden;
        // 不显示
        display: none;
    }

    // 第一部分
    .main_catchword {
        margin-top: 6rem;
        height: 46.75rem;

        .main_catchword_em {
            font-size: 3.5rem;
            line-height: 10rem;
        }

        .main_catchword_p {
            font-size: 3rem;
            padding: 2.5rem 10%;
            text-indent: 6rem;
            height: 6rem;
            line-height: 6rem;
        }
    }

    // 平台功能
    .platformFunction {
        height: 100rem;

        .technicalSupport_shade {
            width: calc(100% - 3rem);
            margin: 3.125rem 0;
            padding: 1.25rem 2rem;

            h2 {
                font-size: 4rem;
            }

            .item_lists {
                font-size: 2.1rem !important;
                padding: 0rem 0rem;
                line-height: 5.5rem !important;

                /deep/ .el-tabs__item {
                    font-size: 2.3rem;
                }
            }

            .item_name {
                // font-size: 2.5rem !important;
                // padding: 0px 0.8rem;
                // line-height: 10.5rem!important;
            }

            .tabBody {
                height: 50rem;

                .tabBody-img {
                    // //  width: 500px;
                    // img{
                    //   width: 100%;
                    //   height: 80%;
                    // }
                }

                .tabBody-content {
                    overflow: hidden;

                    h3 {
                        width: 78%;
                        margin: 1.875rem 20% 1.25rem 11%;
                        font-size: 3.875rem;
                    }

                    p,
                    .describeArr {
                        margin-top: 0.2rem;
                        line-height: 5rem;
                        text-indent: 4rem;
                        text-align: left;
                        width: 96%;
                        margin-left: 1%;
                        font-size: 2.875rem;
                        text-overflow: ellipsis;
                        // 溢出隐藏
                        overflow: hidden;
                    }
                }
            }
        }

        //平台功能 ->产品场景 -> 轮播图
        .el-carousel {
            height: 58.125rem !important;

            /deep/ .el-carousel__container {
                position: relative;
                height: 57.75rem;
            }

            /deep/ .el-carousel__button {
                width: 2rem;
                height: 0.325rem;
            }

            .el-carousel__item {
                height: 54.5625rem !important;

                h3 {
                    font-size: 3.5rem;
                }

                // opacity: 0;
                img {
                    height: 49rem !important;
                    width: 86rem !important;
                }
            }
        }
    }

    // 技术支持
    .container_technicalSupport {
        height: 60rem;
        // width: 1915PX;
        margin: 0 auto;


        .technicalSupport {
        // background: url('../../assets/img/6.jpg');
            background-size: cover;
            background-position: 100%;
            height: 480px;


            .technicalSupport_shade {
                color: rgb(22, 22, 22);
                width: calc(100% - 5rem);
                height: calc(100% - 0.25rem);
                // margin: 50px 0;
                padding: 10px 4rem;
                text-align: center;
                background: #ffffff52;


            .technicalSupport_ul {
                width: 100%;
                // margin: 10px 0 0 0;
                // width: 1372px;
                margin: auto;
            }

            .technicalSupport_ul > li {
                list-style: none;
                width: 26%;
                margin-left: 3%;
                height: 16.5rem;
                float: left;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                margin-top: 15px;
            }

            .technicalSupport_ul > li:hover {
                box-shadow: 0 2px 8px rgba(17, 248, 237, 0.15);
            }

            .technicalSupport_ul > li:hover,
            .technicalSupport_ul > li > i {
                color: #2db5a3;
            }

            .technicalSupport_ul > li > i {
                display: block;
                font-size: 38px;
                margin: 4rem 0 20px 0;
            }

            .technicalSupport_ul > li > em {
                //letter-spacing: 2px;
                font-style: normal;
                font-size: 14px;
            }

            .technicalSupport_ul > li > p {
                //letter-spacing: 2px;
                line-height: 30px;
            }
        }
    }
}

    .item {
        // display: inline;
        position: relative;
        height: 980px;
        width: 1920px;
        margin-bottom: 126px;
        // margin-top: -20px;
    }

    //轮播图一（滑块）
    .swiper_slide_info2 {
        width: 80%;
        height: 960px;
        position: relative;
        // position: absolute;
        text-align: center;
        margin: 1% 12%;
        // text-indent: 6rem;
        // line-height: 200%;
        font-size: 30px;
        z-index: 5;
        color: white;

        // border: 1px solid red;
        /*导航效果-end*/

        .g_sycen {
            height: 910px;
            position: relative;
            z-index: 20;
            overflow-x: hidden;
            overflow-y: auto;
            // line-height: 1;
            // box-sizing: border-box;
            margin: 0;
            padding: 0;
        }

        /*公共*/

        .g_content {
            width: 1200px;
            height: 686px;
            margin: auto;
        }

        /*规范*/

        .g_word1 {
            font-size: 30px;
            // line-height: 1.75;
            //letter-spacing: 1px;
        }

        .g_word2 {
            font-size: 24px;
            line-height: 1.75;
            //letter-spacing: 1px;
        }

        .g_word3 {
            font-size: 16px;
            // line-height: 1.75;
            //letter-spacing: 1px;
        }

        .g_word4 {
            font-size: 14px;
            // line-height: 1.75;
            //letter-spacing: 1px;
        }

        /*载入效果*/

        .g_fadein1 {
            -webkit-animation: fadeIn 1s ease both;
            animation: fadeIn 1s ease both;
            animation-delay: 0.3s;
        }

        .g_pt1qieph {
            display: none;
        }

        .g_section1 {
            padding-top: 115px;
            position: relative;
        }

        /* 整体内部框 */

        .g_pt1beinr {
            display: none;
        }

        /* 内部框 */

        .g_pt1beinr.act {
            display: block;
        }

        .g_pt1bei {
            position: relative;
            z-index: 20;
            width: 893px;
            height: 415px;
        }

        .g_part1tle {
            width: 1050px;
            height: 90px;
            // position: relative;
            // border: 1px solid rgb(255, 251, 0);
            text-align: left;
            // left: -80px;
        }

        .g_part1tle h2 {
            color: #0c0c0c;
            // color: #111111;
            font-weight: lighter;
            line-height: 30px;
            font-size: 30px;
            //letter-spacing: 1px;
        }

        .g_part1tle h3 {
            color: #050505;
            // color: #111111;
            font-weight: bold;
            line-height: 30px;
            font-size: 30;
            //letter-spacing: 1px;
        }

        .g_part1qie {
            width: 1050px;
            // height: 455px;
            margin-top: 40px;
            position: relative;
            z-index: 2;
        }

        .g_pt1qie {
            width: 193.5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            height: 725px;
            background: #3ad5d4;
            border-radius: 10px;
            /* background: #0044d2;  */
            /* background: #0a0c24; */
            z-index: 10;
        }

        .g_pt1beixx {
            width: calc(100% - 193.5px);
            background-size: cover;
            padding: 10px 0 0 92px;
            height: 480px;
            position: relative;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        .g_pt1beifl {
            // width: calc(100% - 540px);
            width: 660px;
            height: 400px;
            // border: 1px solid brown;
            text-align: left;
        }

        .g_pt1beiflp {
            width: 393px;
            height: 20px;
            color: rgb(10, 10, 10);
            font-size: 16px;
            // line-height: 16px;
            margin-bottom: 5px;
            //letter-spacing: 1px;
        }

        .g_pt1beifl .g_word1 {
            color: rgb(10, 10, 10);
            //letter-spacing: 1px;
        }

        .g_pt1beiwz {
            margin-top: 24px;
        }

        .g_pt1beiwz .g_word3 {
            color: rgb(10, 10, 10);
            padding-left: 20px;
            position: relative;
            transition: all 0.36s;
        }

        .g_pt1beiwz .g_word3::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fff;
            transform: translateY(-50%);
        }

        .g_pt1beiwz .g_word4 {
            color: #5a5b5e;
            padding-left: 20px;
        }

        .g_pt1qietu {
            width: 100px;
            height: 80px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: relative;
        }

        .g_pt1qietu img {
            width: 100%;
            height: 100%;
            margin: 10px auto;
        }

        .g_pt1qietu1 {
            position: absolute;
            top: 0;
            left: 0;
            // border: 1px solid burlywood;
        }

        .g_pt1qietu2 {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            //  border: 1px solid burlywood;
        }

        //滑块
        .g_pt1qie .g_pt1qienr {
            // height: 25%;
            width: 220px;
            height: 180px;
            margin: 0;
            padding: 0;
            // width: 100%;
            position: relative;
            cursor: pointer;
            // border: 1px solid yellow;
        }

        .g_pt1qieb {
            position: absolute;
            z-index: 20;
            width: 193.5px;
            height: 113px;
        }

        .g_pt1qieb p {
            margin-top: 30px;
            font-size: 17px;
            line-height: 30px;
            color: #fff;
            height: 30px;
            width: 193.25px;
            // text-align: center;
            // border: 1px solid red;
        }

        // .nav-cont-item-bg {
        //   position: absolute;
        //   top: 0;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   margin-top: -12px;
        //   width: 220px;
        //   height: 195px;
        //   border-radius: 20px;
        //   cursor: pointer;
        //   transition: all 0.36s;
        //   background-image: linear-gradient(to bottom right, #148ffa, #0044d2);
        // }
        .nav-cont-item-bg {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            // margin-top: -12px;
            width: 220px;
            height: 180px;
            // height: 195px;
            border-radius: 20px;
            cursor: pointer;
            transition: all 0.36s;
            background-image: linear-gradient(to bottom right, #ffffff, #27dcc7);
        }

        .nav-cont-item-bg.act {
            display: block;
        }

        /* 滑块透明度 */

        .g_pt1qienr.on .g_pt1qietu2 {
            opacity: 1;
        }

        .g_pt1qienr.on .g_pt1qietu1 {
            opacity: 0;
        }
    }

    //  滑块
    .item {
        height: 160.25rem !important;

        img {
            height: 130.25rem;
            width: 179rem;
        }

        .swiper_slide_info2 {
            width: 90%;
            height: 120rem;
            margin: 1% 4%;
            //letter-spacing: 0.6rem;

            .g_part1tle {
                width: 76.625rem;
                height: 9.625rem;
            }

            .g_pt1qieb {
                position: absolute;
                z-index: 20;
                width: 13.09375rem;
                height: 10.0625rem;
            }

            .g_pt1qietu {
                width: 11.25rem;
                height: 10rem;
                top: 36%;
                left: 81%;

                .g_pt1qietu1 {
                }

                .g_pt1qietu2 {
                    // opacity: 1;
                }
            }

            .g_pt1qie {
                width: 21.09375rem;
                height: 92rem;

                p {
                    margin-top: 0;
                    font-size: 3.5rem;
                    line-height: 4.5rem;
                    width: 21.078125rem;
                }
            }

            .g_part1qie {
                width: 86.625rem;

                .nav-cont-item-bg {
                    width: 25.75rem;
                    height: 23.1875rem;
                }
            }

            .g_pt1qienr {
                width: 25.75rem;
                height: 23.1875rem;
            }

            .g_sycen {
                height: 126.875rem;
            }

            .g_fadeup1 {
                font-size: 4.875rem !important;
                //letter-spacing: 0.6rem;
            }

            .g_fadeup2 {
                font-size: 3.3rem;
                height: 6rem !important;
                line-height: 4.875rem !important;
                // width: 80rem;
                //letter-spacing: 0.6rem;
            }

            .g_pt1beixx {
                width: calc(100% - 2.09375rem);
                padding: 0.625rem 0 0 0.75rem;
                height: 80rem;
                margin-top: 10rem;
                left: -5rem;

                .g_pt1beifl {
                    width: 56.25rem;
                    padding-left: 2rem;

                    .g_pt1beiflp {
                        font-size: 3.75rem;
                        height: 4rem;
                        //letter-spacing: 0.6rem;
                        line-height: 4rem;
                    }

                    .g_word1 {
                        font-size: 3rem;
                        //letter-spacing: 0.6rem;
                        height: 3rem;
                        line-height: 3rem;
                    }
                }

                .g_pt1beiwz {
                    .g_word3 {
                        font-size: 3rem;
                        //letter-spacing: 0.6rem;
                        color: #ffffff;
                    }

                    .g_word4 {
                        font-size: 2.8rem;
                        color: #b0b2b4;
                        padding-left: 1.25rem;
                        width: 98%;
                    }
                }
            }
        }
    }

    // 全面解决设备上云
    .equipmentOnCloud {
        height: 184.25rem;

        .main_catchword_em {
            font-size: 4.125rem;
        }

        .main_catchword_p {
            font-size: 3.875rem;
        }

        .equipmentOnCloud_content {
            height: calc(100% - 18.5rem);
            width: calc(100% - 6.625rem);
            padding: 0 6rem;

            ul {
                li {
                    height: 71.75rem;
                    width: calc(44% - 2rem);
                    margin: 1rem 1.25rem 0 0;

                    .equipmentOnCloud_circle {
                        height: 8.25rem;
                        width: 8.25rem;

                        i {
                            font-size: 6.5rem;
                        }
                    }

                    h3 {
                        font-size: 4rem !important;
                    }

                    p {
                        line-height: 5.875rem;
                        font-size: 3.5rem;
                    }

                    .equipmentOnCloud_getMore {
                        height: 7rem;
                        font-size: 4rem;
                        bottom: 3.5rem;
                    }
                }
            }
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;
    }

    small {
        font-size: 4.125rem;
        position: absolute;
        top: 110%;
        left: 74%;
        cursor: pointer;
        padding: 10px 20px;
        background: #2db5a3;
        border-radius: 1.25rem;
        color: white
    }

    small:hover {
        font-weight: bold;
    }

    .title_line {
        width: 8%;
        height: 1px;
        background: #2db5a3;
        margin: 4.2% 0 0 68%;
        display: none;
    }
}
</style>
